@import "styles/general.style"

$anim-time: 0.4s
.modal
	@apply fixed top-0 left-0 w-screen h-screen
	@apply flex justify-center items-center
	@apply bg-gray-900 bg-opacity-50
	animation: fade-in $anim-time ease-in-out

	&-content
		@apply rounded-md relative
		animation: translate-up $anim-time ease-in-out
		width: 90%
		background-color: $background-tertiary
		@screen md
			@apply w-3/4
		@screen lg
			@apply w-1/2
		@screen 2xl
			@apply w-1/3

		img
			@apply mx-auto mt-5 mb-0 w-3/5
			@screen lg
				@apply mt-16 w-2/5

		& .signup
			@apply mb-0 pt-0 pb-3
			background-color: $background-tertiary
			@screen md
				@apply mt-0
			@screen lg
				@apply w-2/3
				&-input
					@apply w-full
				h3
					@apply text-3xl
				p
					@apply text-base

		&-close
			@apply absolute cursor-pointer
			top: 25px
			right: 15px
			div
				@apply rounded-lg bg-gray-500 w-6
				transform-origin: 55%
				height: 3px
				&:first-child
					transform: rotate(-45deg)
				&:nth-child(2)
					transform: rotate(45deg)
					transform-origin: 60%

@keyframes fade-in
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes translate-up
	0%
		transform: translateY(100px)
	100%
		transform: translateY(0px)