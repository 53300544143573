@tailwind base
@tailwind components
@tailwind utilities

@import url("https://use.typekit.net/ibn6tlr.css")

@import "styles/variables.style"

\:root
  font-family: $font-primary
  font-style: normal
//typography START//

h1
  @apply text-4xl font-black
  @screen lg
    @apply text-6xl
h2
  @apply text-3xl font-bold
  @screen lg
    @apply text-5xl

h3
  @apply text-2xl font-bold
  @screen lg
    @apply text-4xl
p, a
  @apply text-lg font-medium
  @screen lg
    @apply text-xl
//typography END//


// Local resources
//https://github.com/sass/sass/issues/1015
$asset-base-path: '../assets/' !default

/// Asset URL builder
/// @access private
/// @param {String} $type - Asset type, matching folder name
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the asset
@function asset($type, $file)
  @return url($asset-base-path + '/' + $type + '/' + $file)


/// Image asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the image
/// @require {function} asset
@function image($file)
  @return asset('images', $file)


/// Font asset helper
/// @access public
/// @param {String} $file - Asset file name, including extension
/// @return {URL} - A `url()` function leading to the font
/// @require {function} asset
@function font($file)
  @return asset('fonts', $file)

