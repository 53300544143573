@import "styles/general.style"

.signup
  @apply flex flex-col items-center mb-32 rounded-lg py-8
  background-color: #131627

  @screen md
    @apply my-48
  @screen lg
    @apply w-4/6 mx-auto
    margin-top: 400px
  @screen xl
    @apply w-1/2

  &-content
    @apply  mb-8 text-center
    h3
      color: #FFDF9B
      @screen md
        @apply text-4xl
      @screen 2xl
        @apply text-5xl
    p
      @apply text-gray-400 text-sm mt-2
      @screen md
        @apply text-xl
      @screen 2xl
        @apply text-2xl


  &-spam
    @apply text-gray-400 text-xs mt-2
    @screen md
      @apply text-base mt-4
    @screen 2xl
      @apply text-2xl
