.signup-component
  @apply flex text-gray-700 w-3/4
  @screen md
    @apply w-3/5
  @screen xl
    @apply mt-4

  & > .icon-container
    @apply w-1/4 bg-white flex justify-center rounded-l-lg

    & > .icon
      @apply h-full text-sm
      @screen md
        @apply text-lg
      @screen xl
        @apply text-xl
      @screen 2xl
        @apply text-3xl

  input // Email input
    @apply w-3/4 text-sm px-2
    @apply appearance-none rounded-none
    @screen md
      @apply text-base
    @screen xl
      @apply text-lg

    &::placeholder
      @apply text-gray-700 font-semibold
    &:focus
      @apply outline-none

  button  // Submit button
    @apply rounded-r-lg bg-blue-800 py-2 px-3
    @apply select-none text-gray-100 font-semibold text-sm
    transition: 0.1s
    @screen md
      @apply text-lg
    @screen xl
      @apply text-xl

    &:focus, &:active, &:hover
      @apply outline-none bg-blue-600 text-gray-200