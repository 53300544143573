@import "styles/general.style"

.person-card
  @apply flex flex-col items-center
  h3
    @apply text-gray-100 mt-2
    @screen lg
      @apply text-3xl
    @screen xl
      @apply text-xl
    @screen 2xl
      @apply text-2xl
  p
    @apply text-gray-200 font-medium
    @screen lg
      @apply text-xl
    @screen xl
      @apply text-base
    @screen 2xl
      @apply text-xl

  img
    @apply border-solid mb-5 border-2 w-56 h-56 rounded-full
    border-color: #13E6C8

    @screen xl
      @apply w-48 h-48
    @screen 2xl
      @apply w-56 h-56