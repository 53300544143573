$text-primary: #13E6C8
$text-secondary: #008291
$text-tertiary: #464646
$text-quaternary: #f7d897
$text-contrast: #fff

$text-product-primary: #023f5e

$background-primary: #13E6C8
$background-secondary: #f3f3f3
$background-tertiary: #242447
$background-quaternary: #023F5E

$background-footer-desktop: #1e223e
$background-footer-mobile: #242447


$font-primary: "museo-sans"